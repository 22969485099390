<template>
  <div class="account-delete-container">
    <div class="account-delete-content">
      <div class="account-delete-title">
        {{ $t("delete_your_account") | capitalize }}
      </div>
      <div class="account-delete-description">
        {{ $t("account_delete_description") }}
      </div>
      <div class="account-delete-buttonsgroup account-delete-password">
        <label>
          {{ $t("password_close_account") | capitalize }}
        </label>
        <input
          v-model="password"
          :type="show ? 'text' : 'password'"
          class="account-delete-button"
          :placeholder="$t('password') | capitalize"
          :class="{ 'is-invalid': !passwordValidation }"
        />
        <eyes
          class="account-delete-password-eyes"
          @click.native="show = !show"
        />
        <div class="is-invalid" v-if="!passwordValidation">
          {{ $t("incorrect_password") | capitalize }}
        </div>
        <!-- <label>
        {{ $t("subscription_mailing") | capitalize }}
      </label>
      <b-button
        @click="newsletters = !newsletters"
        class="account-delete-button"
        :class="{ active: newsletters }"
      >
        <div class="account-delete-button-content">
          <div class="account-delete-button-text">
            {{ $t("no_emails") | capitalize }}
          </div>
          <check v-if="newsletters" />
          <no-select v-else />
        </div>
      </b-button> -->
      </div>
    </div>
    <div class="account-delete-footer">
      <div class="account-delete-buttonsgroup">
        <b-button
          @click="deleteValidation = !deleteValidation"
          class="account-delete-button"
          :class="{ active: deleteValidation }"
        >
          <div class="account-delete-button-content">
            <div class="account-delete-button-text">
              {{ $t("account_delete_validation") | capitalize }}
            </div>
            <check v-if="deleteValidation" />
            <no-select v-else />
          </div>
        </b-button>
        <b-button
          class="b-btn-checkout account-delete-button-finish"
          block
          variant="primary"
          :disabled="!passwordValidation || !deleteValidation"
          @click="deleteAccount()"
        >
          {{ $t("finish") | capitalize }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Check from "../svg/check.vue";
import NoSelect from "../svg/noselect.vue";
import Eyes from "../svg/eyes.vue";

export default {
  components: { Check, NoSelect, Eyes },
  name: "AccountDelete",

  data() {
    return {
      password: "",
      show: false,
      passwordValidation: true,
      newsletters: false,
      deleteValidation: false,
    };
  },

  computed: {
    ...mapGetters("users", ["getUser", "getUserName"]),
    user_data() {
      return { email: this.getUserName, password: this.password };
    },
  },

  watch: {
    password() {
      this.passwordValidation = true;
    },
  },

  methods: {
    ...mapActions("users", ["setToken", "deleteUserData", "fetchUserData"]),
    async deleteAccount() {
      let response = await this.deleteUserData(this.user_data);
      this.passwordValidation = response != "error";
      if (this.passwordValidation) {
        window.location.href = '/logout/';
      }
    },
  },

  async mounted() {
    window.zE("webWidget", "hide");
  },

  async created() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      this.setToken(token);
    }
    this.fetchUserData();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.account-delete-container {
  height: 100vh;

  :last-child {
    margin-top: auto;
  }

  .account-delete-content {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1.5em;
  }

  .account-delete-footer {
    display: flex;
    position: fixed;
    bottom: 0;
    gap: 1.5em;
    padding: 1.5em;
  }

  .account-delete-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 28px;
    text-align: left;
  }

  .account-alert-text {
    text-align: left;
    color: #f95658 !important;
    font-weight: 500 !important;
  }

  .account-delete {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-password {
      display: block !important;

      &-eyes {
        position: absolute;
        right: 2.5em;
        margin-top: 1em;
      }
    }

    &-description {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #555b62;
    }

    &-buttonsgroup {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .button-expand {
        padding: 0em 1em !important;

        &-header {
          padding: 1em 0em;
        }
      }

      .is-invalid {
        color: #f95658 !important;
      }

      .account-delete-button.is-invalid {
        border: 2px solid #f95658 !important;
        font-weight: 500 !important;
        background: transparent !important;
      }

      .account-delete-button {
        background: #ffffff;
        border: 2px solid #eaeaea;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 1em;
        width: 100%;

        &:focus {
          box-shadow: none;
        }

        &:focus-visible {
          outline: none;
        }

        &-finish {
          font-size: 18px;
          padding: 1em;
        }

        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;
        }

        &-text {
          width: 90%;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #555b62;
        }
      }

      .btn-secondary:not(:disabled):not(.disabled).is-invalid {
        border: 1px solid #f95658 !important;
        background: #feeeee;
        box-shadow: 0px 0px 0px 1px #feeeee !important;
      }

      .btn-secondary:not(:disabled):not(.disabled).active {
        border: 1px solid #0090f8 !important;
        box-shadow: 0px 0px 0px 1px #0090f8 !important;

        .account-delete-button-text {
          color: black;
        }
      }

      .account-expand-summary {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .expand-summary-item {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 14px;
          color: #555b62;
          text-align: left;

          &:last-child {
            padding-bottom: 1em;
          }
        }

        .expand-summary-item.is-invalid {
          color: #f95658 !important;
          font-weight: 500 !important;
          border: none !important;
          background: transparent !important;
        }
      }

      .button-expand-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .button-expand {
        &-title {
          color: #050328;
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
        }

        &-body {
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          border-top: #eaeaea 2px solid;
          padding: 1em 0em;
        }

        &-inputgroup {
          display: flex;
          flex-direction: column;
        }

        &-label {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 14px;
          color: #555b62;
          text-align: left;
        }

        &-input {
          background: #ffffff;
          border: 1px solid #8b949f;
          box-sizing: border-box;
          border-radius: 8px;
          font-family: "Open Sans";
          font-size: 14px;
          padding: 0.75em;
          margin-top: 0.5em;

          &:focus-visible {
            border: 1px solid #0090f8 !important;
            box-shadow: 0px 0px 0px 1px #0090f8 !important;
            outline: none;
            background: white;
          }
        }

        .is-invalid {
          border: 1px solid #f95658 !important;
          background: #feeeee;

          &:focus-visible {
            border: 1px solid #f95658 !important;
            box-shadow: 0px 0px 0px 1px #feeeee !important;
          }
        }
      }
    }
  }
}
</style>