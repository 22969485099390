<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8.5" r="7" stroke="#8B949F" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: "NoSelect",
  props: {
    iconColor: {
      type: String,
      default: "#0090F8",
    },
  },
};
</script>

<style scoped>
svg {
  margin: auto;
}
</style>