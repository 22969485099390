<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3396 20.9996C20.2434 20.9998 20.1482 20.9805 20.0594 20.9427C19.9706 20.905 19.8899 20.8496 19.8221 20.7798L3.71611 4.27979C3.58461 4.138 3.51239 3.94918 3.51483 3.75361C3.51727 3.55804 3.5942 3.37118 3.72919 3.23288C3.86419 3.09458 4.04659 3.01578 4.23749 3.01328C4.42839 3.01077 4.61269 3.08477 4.7511 3.21948L20.8571 19.7195C20.9594 19.8244 21.0291 19.958 21.0573 20.1034C21.0855 20.2489 21.071 20.3996 21.0156 20.5366C20.9602 20.6736 20.8665 20.7907 20.7461 20.8731C20.6258 20.9555 20.4843 20.9996 20.3396 20.9996Z"
      fill="#8B949F"
    />
    <path
      d="M11.9204 14.8051L9.55072 12.3775C9.53715 12.3637 9.51969 12.3546 9.50081 12.3515C9.48193 12.3484 9.46257 12.3514 9.44546 12.3601C9.42834 12.3689 9.41433 12.3829 9.40539 12.4002C9.39645 12.4175 9.39303 12.4373 9.39561 12.4567C9.49128 13.0865 9.77995 13.6689 10.2199 14.1196C10.6598 14.5703 11.2283 14.866 11.8431 14.964C11.862 14.9667 11.8813 14.9632 11.8982 14.954C11.9151 14.9449 11.9288 14.9305 11.9373 14.913C11.9459 14.8954 11.9488 14.8756 11.9458 14.8563C11.9427 14.8369 11.9339 14.819 11.9204 14.8051Z"
      fill="#8B949F"
    />
    <path
      d="M12.6526 9.19479L15.026 11.6253C15.0395 11.6393 15.057 11.6485 15.076 11.6517C15.095 11.6549 15.1144 11.652 15.1317 11.6432C15.1489 11.6344 15.163 11.6203 15.1719 11.6029C15.1809 11.5854 15.1842 11.5655 15.1815 11.546C15.0861 10.9154 14.7972 10.3322 14.3567 9.88088C13.9162 9.42959 13.3469 9.13365 12.7313 9.03589C12.7123 9.03287 12.6928 9.03611 12.6756 9.04513C12.6584 9.05415 12.6445 9.0685 12.6358 9.08612C12.627 9.10374 12.624 9.12374 12.627 9.14326C12.63 9.16277 12.639 9.18081 12.6526 9.19479Z"
      fill="#8B949F"
    />
    <path
      d="M23.0392 12.8137C23.1907 12.5702 23.2708 12.2872 23.27 11.9984C23.2692 11.7096 23.1875 11.427 23.0347 11.1844C21.824 9.26624 20.2532 7.63687 18.4925 6.47203C16.5419 5.18203 14.3914 4.5 12.272 4.5C11.1547 4.50157 10.045 4.6882 8.98586 5.05266C8.95621 5.06276 8.92958 5.08046 8.90851 5.10409C8.88744 5.12771 8.87262 5.15647 8.86546 5.18763C8.85831 5.21878 8.85905 5.25129 8.86763 5.28207C8.87621 5.31284 8.89234 5.34085 8.91448 5.36344L11.076 7.57781C11.0984 7.60086 11.1264 7.61751 11.157 7.62614C11.1876 7.63477 11.22 7.63508 11.2508 7.62703C11.9834 7.44411 12.7497 7.45752 13.4759 7.66595C14.202 7.87438 14.8635 8.27082 15.3968 8.81709C15.93 9.36337 16.317 10.0411 16.5204 10.785C16.7239 11.5289 16.737 12.3139 16.5584 13.0645C16.5506 13.096 16.551 13.129 16.5594 13.1603C16.5678 13.1916 16.584 13.2202 16.6065 13.2431L19.7155 16.4306C19.7479 16.4638 19.7912 16.4834 19.8369 16.4855C19.8827 16.4875 19.9275 16.472 19.9626 16.4419C21.1594 15.3968 22.1965 14.1739 23.0392 12.8137Z"
      fill="#8B949F"
    />
    <path
      d="M12.2866 16.5002C11.6217 16.5002 10.9654 16.3456 10.3673 16.048C9.76917 15.7503 9.24481 15.3175 8.83374 14.782C8.42268 14.2466 8.13564 13.6225 7.99427 12.9569C7.8529 12.2912 7.86089 11.6014 8.01764 10.9394C8.02542 10.9079 8.02507 10.8749 8.01665 10.8436C8.00823 10.8123 7.99202 10.7838 7.9696 10.7608L4.91129 7.62627C4.87887 7.59301 4.83542 7.57344 4.78958 7.57144C4.74373 7.56945 4.69882 7.58517 4.66376 7.61549C3.54778 8.59096 2.51324 9.77783 1.56747 11.1649C1.40192 11.4083 1.31075 11.6966 1.30553 11.9933C1.30032 12.2899 1.3813 12.5814 1.53819 12.8308C2.7466 14.7681 4.30137 16.3999 6.03505 17.5488C7.98836 18.8439 10.0867 19.5002 12.272 19.5002C13.4006 19.4971 14.5218 19.3144 15.5957 18.9588C15.6256 18.949 15.6525 18.9315 15.6739 18.9079C15.6953 18.8844 15.7105 18.8556 15.7179 18.8243C15.7253 18.7931 15.7247 18.7604 15.7162 18.7294C15.7077 18.6985 15.6916 18.6703 15.6693 18.6475L13.4973 16.4228C13.4749 16.3999 13.4471 16.3833 13.4165 16.3746C13.386 16.366 13.3537 16.3656 13.323 16.3736C12.9837 16.4578 12.6358 16.5003 12.2866 16.5002Z"
      fill="#8B949F"
    />
  </svg>
</template>

<script>
export default {
  name: "Eyes",
  props: {
  },
};
</script>

<style scoped>
svg {
  margin: auto;
}
</style>